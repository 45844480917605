var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "custom_table_submitted_reports",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('b-button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview"
          },
          on: {
            "click": function click($event) {
              return _vm.previewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1)], 1)]);
      }
    }])
  }), _c('b-modal', {
    ref: "preview-report-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('div', {
    staticClass: "preview-report",
    staticStyle: {
      "height": "65vh",
      "overflow": "auto"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.previewModal.htmlContent)
    }
  })])], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_vm.previewModal.isResponsible && !_vm.previewModal.Reviewed && _vm.previewModal.isDailyProduction ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.viewReport(_vm.previewModal.ID);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Append report ")], 1) : _vm._e(), _vm.previewModal.isReviewer && !_vm.previewModal.Reviewed ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.setReportReviewed();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }), _vm._v(" Mark as Reviewed ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }