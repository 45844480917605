var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.details.title,
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Subscriber list details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.category_name.id,
      "value": _vm.data.category_name,
      "label": _vm.controls.category_name.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.frequency.id,
      "value": _vm.data.frequency,
      "label": _vm.controls.frequency.label,
      "readonly": _vm.controls.frequency.readonly,
      "options": _vm.controls.frequency.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.department.id,
      "value": _vm.data.department,
      "label": _vm.controls.department.label,
      "readonly": _vm.controls.department.readonly,
      "options": _vm.controls.department.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.reportState.id,
      "value": _vm.data.deleted,
      "label": _vm.controls.reportState.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": true
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.responsibles.id,
      "value": _vm.data.responsibles,
      "label": _vm.controls.responsibles.label,
      "readonly": _vm.controls.responsibles.readonly,
      "options": _vm.controls.responsibles.options,
      "allow-empty": true,
      "multiple": true,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.reviewers.id,
      "value": _vm.data.reviewers,
      "label": _vm.controls.reviewers.label,
      "readonly": _vm.controls.reviewers.readonly,
      "options": _vm.controls.reviewers.options,
      "allow-empty": true,
      "multiple": true,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.controls.description.id
    }
  }, [_vm._v("Description")]), _c('ckeditor', {
    ref: "ckeditor",
    attrs: {
      "id": _vm.controls.description.id,
      "editor": _vm.editor,
      "disabled": false,
      "config": _vm.controls.description.config
    },
    on: {
      "blur": _vm.onEditorBlur
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1)], 1), _c('hr')], 1)]), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.submittedReports.title
    }
  }, [_c('submitted-reports-table', {
    attrs: {
      "category-id": _vm.id
    },
    on: {
      "loaded": _vm.onSubmittedReportsLoad
    }
  })], 1) : _vm._e()], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Reports manager'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Report category submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Report category submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }