<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`custom_table_submitted_reports`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <!--
                <b-button size="sm" variant="primary" title="View" @click="viewItem(props.row)">
                    <font-awesome-icon icon="eye" />
                </b-button>

                <b-button size="sm" variant="info" title="Edit" @click="editItem(props.row)">
                    <font-awesome-icon icon="pencil-alt" />
                </b-button>

                <b-button size="sm" variant="danger" title="Delete" @click="deleteItem(props.row)">
                    <font-awesome-icon icon="trash" />
                </b-button>
-->
          <b-button
            class="btn btn-dark btn-sm"
            @click="previewItem(props.row.ID)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </b-button>
        </div>
      </div>
    </table-custom>

    <b-modal
      ref="preview-report-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <div
            class="preview-report"
            style="height:65vh;overflow: auto;"
            v-html="previewModal.htmlContent"
          />
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          v-if="
            previewModal.isResponsible &&
              !previewModal.Reviewed &&
              previewModal.isDailyProduction
          "
          variant="outline-dark"
          class="m-1"
          @click="viewReport(previewModal.ID)"
        >
          <font-awesome-icon icon="plus" /> Append report
        </b-button>

        <b-button
          v-if="previewModal.isReviewer && !previewModal.Reviewed"
          variant="warning"
          class="m-1"
          @click="setReportReviewed()"
        >
          <font-awesome-icon icon="signature" /> Mark as Reviewed
        </b-button>

        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

import { mapState } from "vuex";

export default {
  name: "SubmittedReportsTable",
  props: {
    categoryId: {
      type: [String, Number],
      default: ""
    }
  },
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Submitted by",
            "Submission date",
            "From",
            "To",
            "Department",
            "Category",
            "Reviewed",
            "Actions"
          ],
          filterable: [
            "ID",
            "Submitted by",
            "Submission date",
            "From",
            "To",
            "Department",
            "Category",
            "Reviewed"
          ],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      },
      previewModal: {
        ID: "",
        title: "",
        htmlContent: "",
        isReviewer: false,
        Reviewed: false
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  mounted() {
    if (!this.categoryId) {
      return;
    }

    this.getData();
  },
  methods: {
    onFilter() {},

    async getData() {
      let self = this;

      this.dataTable.isLoading = true;

      //  this.dataTable.dataSet = [];

      let response = [];

      try {
        response = await this.$api.get(
          `users/reports/manager/${this.categoryId}/submitted`
        );

        self.dataTable.isLoading = false;

        if (response.length === 0) return;

        response.forEach(row => {
          row.isSynchronizing = false;
        });

        self.dataTable.dataSet = response;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);

        self.dataTable.isLoading = false;

        self.$form.msgBoxOk("Error occured");
      }
    },
    addItem: function() {
      this.$router.push({
        name: "Reports manager submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(row) {
      this.$router.push({
        name: "Reports manager submission",
        params: {
          action: "view",
          id: row.ID
        }
      });
    },
    editItem: function(row) {
      this.$router.push({
        name: "Reports manager submission",
        params: {
          action: "edit",
          id: row.ID
        }
      });
    },
    async deleteItem(row) {
      let item = this.dataTable.dataSet.find(item => item.ID === row.ID);

      let confirm = await this.$form.showConfirmation(
        `Report '${item.Category}' will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`users/reports/manager/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    previewItem(id) {
      let report = this.dataTable.dataSet.find(item => item.ID === id);

      if (!report) return;

      this.previewModal.ID = report["ID"];
      this.previewModal.title = report["Category"];
      this.previewModal.htmlContent = report["Content"];
      this.previewModal.Reviewed = report["Reviewed"] === "Yes";

      let rev = report["reviewers_id"] ? report["reviewers_id"].split(",") : [];

      this.previewModal.isReviewer = rev.includes(
        this.profile.data.id.toString()
      );

      this.$refs["preview-report-modal"].show();
    },
    setReportReviewed() {
      let self = this;

      this.$api
        .put(`users/reports/${this.previewModal.ID}/reviewed`)
        .then(response => {
          self.previewModal.Reviewed = true;

          self.drawReportsTable();

          self.$form.msgBoxOk(response.message);
        })
        .catch(error => {
          console.log(error);

          self.$form.msgBoxOk(error.message);
        });
    },
    closeModal: function() {
      this.$refs["preview-report-modal"].hide();
    }
  },
  watch: {}
};
</script>

<style scoped></style>
