<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <b-tabs content-class="mt-3" justified>
          <b-tab :title="controls.tabs.details.title" active>
            <form>
              <h5>
                Subscriber list details
                <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
              </h5>
              <hr />

              <b-row>
                <b-col lg="3" md="2" sm="2">
                  <inline-input
                    :id="controls.category_name.id"
                    :value="data.category_name"
                    :label="controls.category_name.label"
                    :readonly="false"
                    :mode="mode"
                    :required="true"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.frequency.id"
                    :value="data.frequency"
                    :label="controls.frequency.label"
                    :readonly="controls.frequency.readonly"
                    :options="controls.frequency.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.department.id"
                    :value="data.department"
                    :label="controls.department.label"
                    :readonly="controls.department.readonly"
                    :options="controls.department.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.reportState.id"
                    :value="data.deleted"
                    :label="controls.reportState.label"
                    :readonly="false"
                    :mode="mode"
                    :required="true"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6" md="6">
                  <inline-select
                    :id="controls.responsibles.id"
                    :value="data.responsibles"
                    :label="controls.responsibles.label"
                    :readonly="controls.responsibles.readonly"
                    :options="controls.responsibles.options"
                    :allow-empty="true"
                    :multiple="true"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="6" md="6">
                  <inline-select
                    :id="controls.reviewers.id"
                    :value="data.reviewers"
                    :label="controls.reviewers.label"
                    :readonly="controls.reviewers.readonly"
                    :options="controls.reviewers.options"
                    :allow-empty="true"
                    :multiple="true"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" sm="12">
                  <!-- <inline-input :id="controls.description.id" :value="data.description" :label="controls.description.label" :readonly="false" :mode="mode" :required="false" @changed="updateField" /> -->

                  <label class="col-form-label" :for="controls.description.id"
                    >Description</label
                  >

                  <ckeditor
                    ref="ckeditor"
                    :id="controls.description.id"
                    :editor="editor"
                    :disabled="false"
                    v-model="data.description"
                    :config="controls.description.config"
                    @blur="onEditorBlur"
                  />
                </b-col>
              </b-row>

              <hr />
            </form>
          </b-tab>

          <b-tab
            :title="controls.tabs.submittedReports.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <submitted-reports-table
              :category-id="id"
              @loaded="onSubmittedReportsLoad"
            />
          </b-tab>
        </b-tabs>
        <hr />

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Reports manager' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Report category submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Report category submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";

import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";

import SubmittedReportsTable from "./SubmittedReportsTable";

Vue.use(CKEditor);

import { mapState, mapActions } from "vuex";

export default {
  name: "ReportsManagerSubmissionForm",
  props: {
    id: { type: String, default: "" },
    action: { type: String, default: "" }
  },
  components: {
    InlineInput,
    InlineSelect,
    FormSubmissionActions,
    ckeditor: CKEditor.component,
    SubmittedReportsTable
  },
  data: function() {
    return {
      editor: InlineEditor,
      mode: undefined,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          details: {
            title: "Report category details"
          },
          submittedReports: {
            title: "Submitted reports"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        category_name: {
          id: "input:category_name",
          label: "Category",
          changed: false,
          readonly: false
        },
        department: {
          id: "select:department",
          label: "Department",
          options: [],
          changed: false,
          readonly: false
        },
        frequency: {
          id: "select:frequency",
          label: "Frequency",
          options: [],
          changed: false,
          readonly: false
        },
        responsibles: {
          id: "select:responsibles",
          label: "Responsibles",
          options: [],
          changed: false,
          readonly: false
        },
        reviewers: {
          id: "select:reviewers",
          label: "Reviewers",
          options: [],
          changed: false,
          readonly: false
        },

        description: {
          data: "",
          config: {
            startupFocus: true,
            startupShowBorders: true
            // readOnly: true,
          },
          readonly: false
        },
        reportState: {
          id: "input:reportState",
          label: "Report state",
          changed: false,
          readonly: true
        }
      },
      data: {
        id: "",
        category_name: "",
        description: "",
        department: {
          id: "",
          label: ""
        },
        frequency: {
          id: "",
          label: ""
        },
        responsibles: [],
        reviewers: [],
        deleted: 0
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    initialize: async function() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      await this.fetchDropdowns();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id);
      }
    },
    async fetchDropdowns() {
      const departments = async () => {
        let response = await this.$api.get("users/reports/departments/all");

        this.controls.department.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      };

      const frequencies = async () => {
        let response = await this.$api.get("users/reports/frequencies");

        this.controls.frequency.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      };

      const users = async () => {
        let response = await this.$api.get("users");

        this.controls.responsibles.options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));

        this.controls.reviewers.options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      return Promise.all([departments(), frequencies(), users()]);
    },
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";
      let url = "";

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = "users/reports/manager";
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `users/reports/manager/${this.data.id}`;
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateField(field, value, mode) {
      this.$form.updateField("users/reports/manager", this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField(
        "users/reports/manager",
        this,
        id,
        value,
        this.mode
      );
    },
    onEditorBlur() {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      this.updateField("description", this.data.description, this.mode);
    },

    async fetchData(id) {
      let self = this;

      this.isLoading = true;

      return this.$api
        .get(`users/reports/manager/${id}`)
        .then(response => {
          this.isLoading = false;

          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.category_name = response["Category"];

          self.data.description = response["Description"];

          self.data.department = {
            id: response["department_id"],
            label: response["Department"]
          };

          self.data.frequency = {
            id: response["frequency_id"],
            label: response["Frequency"]
          };

          if (response["res_ids"]) {
            let res = response["res_ids"].split(",");

            self.data.responsibles = self.controls.responsibles.options.filter(
              i => res.includes(i.id.toString())
            );
          }

          if (response["rev_ids"]) {
            let rev = response["rev_ids"].split(",");

            self.data.reviewers = self.controls.reviewers.options.filter(i =>
              rev.includes(i.id.toString())
            );
          }

          self.data.deleted = response["Deleted"] == 0 ? "Active" : "Deleted";
        })
        .catch(error => {
          this.isLoading = false;

          console.log(error);
        });
    },
    onSubmittedReportsLoad(count) {
      if (count > 0)
        this.controls.tabs.submittedReports.title = `Submitted reports (${count})`;
    }
  },
  watch: {}
};
</script>

<style scoped>
.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 500px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}
</style>
